import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import api from '../../service/api';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';

export const ListaUltimasAtividades = (props) => {

    const emptyAtividade = {
        prioridade: '',
        idprioridade: 0,
        idclienteplanoitens: 0,
        atividade: '',
        item: '',
        area: '',
        datalimite: '',
        tipoatividade: '',
        tipoexecutor: '',
        execucao: '',
        undperiodicidade: '',
    }

    const [listaAtividades, setListaAtividades] = useState(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [atividade, setAtividade] = useState(emptyAtividade);
    const [loading, setLoading] = useState(true);
    const dt = useRef(null);

    useEffect(() => {
        setLoading(true);
        api.get(`/atividade/ultimas/${sessionStorage.getItem("idcliente")}`)
            .then(res => {
                setListaAtividades(res.data);
                setLoading(false);
            });
    }, []);

    const mostrarAtividade = (atividade) => {
        setLoading(true);
        const idAtividade = atividade['idclienteplanoitens']
        api.get(`/atividade/${idAtividade}`)
            .then(res => {
                setAtividade(res.data);
                setDisplayBasic(true);
                setLoading(false);
            })
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" onClick={() => mostrarAtividade(rowData)} />
            </React.Fragment>
        );
    }

    const onHide = (name) => {
        if (name === 'displayBasic') {
            setDisplayBasic(true);
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Fechar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }

    return (
        <>
            <DataTable ref={dt} dataKey="idclienteplanoitensexecucao" value={listaAtividades} >
                <Column field="idclienteplanoitens" header="N°" style={{ width: '15%' ,  textAlign: 'center' }} ></Column>
                <Column field="descricao" header="Área" style={{ width: '25%' }}></Column>
                <Column field="tipoexecucao" header="Status" style={{ width: '30%' }}></Column>
                <Column field="tipoatividade" header="Atividade" style={{ width: '20%' }}></Column>
                <Column body={actionBodyTemplate} style={{ width: '10%' ,  textAlign: 'right' }}></Column>
            </DataTable>

            <Dialog header={"Atividade " + atividade.idclienteplanoitens} visible={displayBasic}
                style={{ width: '70vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="text-500">Atividade
                    <h5> {atividade.atividade} </h5>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Prioridade
                        <h5> {atividade.prioridade} </h5>
                    </div>
                    <div className="p-col text-500">Data Limite
                        <h5> {atividade.datalimite} </h5>
                    </div>
                    <div className="p-col text-500">Execução
                        <h5> {atividade.execucao} </h5>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Área
                        <h5> {atividade.area} </h5>
                    </div>
                    <div className="p-col text-500">Item
                        <h5> {atividade.item} </h5>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Tipo de Atividade
                        <h5> {atividade.tipoatividade} </h5>
                    </div>
                    <div className="p-col text-500">Periodicidade
                        <h5> {atividade.undperiodicidade} </h5>
                    </div>
                    <div className="p-col text-500">Responsável
                        <h5> {atividade.tipoexecutor} </h5>
                    </div>
                </div>
            </Dialog>
        </>
    )
}