import React, { Component } from 'react';
import { Chart } from '../../components/chart/Chart';
import AppContentContext from '../../AppContentContext';
import api from '../../service/api';
import { Calendar } from '../../components/calendar/Calendar';
import { Button } from '../../components/button/Button';
import { format } from "date-fns";
import { Toolbar } from '../../components/toolbar/Toolbar';
import { Galleria } from '../../components/galleria/Galleria';
import { Timeline } from '../../components/timeline/Timeline';
import { Card } from '../../components/card/Card';

export class testeGrafico extends Component {

    constructor(props) {
        super(props);

        this.events1 = [
            { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
            { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
            { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
            { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
        ];

        this.state = {
            images: null,
            activeIndex: 2            
        };



    }

    async componentDidMount() {
        const newdate = new Date(), y = newdate.getFullYear(), m = newdate.getMonth();
        var firstday = new Date(y, m, 1);
        var ultimodia = new Date(y, m + 1, 0);
        this.setState({ dateIni: firstday });
        this.setState({ dateFim: ultimodia });
    }

    btnClick() {
        console.log(this.state.dateIni);
        console.log(this.state.dateFim);
        let datafimformatada = format(this.state.dateFim, "dd-MM-yyyy");
        let datainiformatada = format(this.state.dateIni, "dd-MM-yyyy");
        console.log(datainiformatada);
        api.get(`/prioridade/dataini/${datainiformatada}/datafim/${datafimformatada}/id/6`)
            .then(response => { this.setState({ grafico: response.data }) });
        console.log(`prioridade/dataini/${this.state.dateIni}/datafim/${this.state.dateFim}/id/6`);
    }


    render() {
        const customizedMarker = (item) => {
            return (
                <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.color }}>
                    <i className={item.icon}></i>
                </span>
            );
        };
        return (

            <div>

                <div className="content-section introduction">
                    {/* <AppInlineHeader changelogText="chart"> */}
                    <h2>TESTE</h2>
                    {/* <p>A doughnut chart is a variant of the pie chart, with a blank center allowing for additional information about the data as a whole to be included.</p> */}
                    {/* </AppInlineHeader> */}
                </div>

                <div className="card">
                    <h5>Left Align</h5>
                    <Timeline value={this.events1} content={(item) => item.status} />
                </div>
            </div>


        )
    }
}