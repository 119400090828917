import React from 'react';
import { GraficoOcorrenciasAreas } from './ocorrenciasAreas';
import { GraficoOcorrenciasGravidade } from './ocorrenciasGravidade';
import { GraficoOcorrenciasStatus } from './ocorrenciasStatus';
import { CardsOcorrencia } from './cardsOcorrencia';
import { Panel } from '../../components/panel/Panel';

export const DashboardOcorrencias = () => {

    const idCliente = sessionStorage.getItem("idcliente");

    const getGrafico = (tipo) => {
        if (tipo === "OcorrenciasAreas") {
            return (
                <React.Fragment>
                    <GraficoOcorrenciasAreas idCliente={idCliente} />
                </React.Fragment>
            )
        }
        if (tipo === "OcorrenciasGravidade") {
            return (
                <React.Fragment>
                    <GraficoOcorrenciasGravidade idCliente={idCliente} />
                </React.Fragment>
            )
        }
        if (tipo === "OcorrenciasStatus") {
            return (
                <React.Fragment>
                    <GraficoOcorrenciasStatus idCliente={idCliente} />
                </React.Fragment>
            )
        }
    }

    return (
        <div>
            <div className="content-section introduction">
                <h2>Ocorrências</h2>
            </div>

            <div className="p-grid p-fluid dashboard">
                <CardsOcorrencia idCliente={idCliente} />
            </div>

            <Panel header={'Grupo de Área'}>
                {getGrafico('OcorrenciasAreas')}
            </Panel>

            <div className="p-grid p-mt-3">
                <div className="p-col-6">
                    <Panel header={'Gravidade'}>
                        {getGrafico('OcorrenciasGravidade')}
                    </Panel>
                </div>
                <div className="p-col-6">
                    <Panel header={'Status'}>
                        {getGrafico('OcorrenciasStatus')}
                    </Panel>
                </div>
            </div>
        </div>
    )
}