import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import api from '../../service/api';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { Accordion, AccordionTab } from '../../components/accordion/Accordion';
import { Timeline } from '../../components/timeline/Timeline';
import '../../csspersonalizado/timeline.css';
import '../../csspersonalizado/galleria.css';
import { Galleria } from '../../components/galleria/Galleria';
import { Card } from '../../components/card/Card';
import ReactRoundedImage from "react-rounded-image";
import { Avatar } from 'primereact/avatar';

export const ListaUltimasOcorrencias = () => {

    const emptyOcorrencia = {
        idocorrencia: 0,
        descricao: '',
        status: '',
        area: '',
        gravidade: '',
        referencia: '',
        qtddiasaberto: 0,
        recomendacaotecnica: '',
        enquadramentotecnico: ''
    }

    const emptyStatus = {
        idocorrenciasstatus: 0,
        idocorrencia: 0,
        datahora: '',
        qtddias: 0,
        tipostatus: 'opa',
        corhexa: '',
        observacao: '',
        nome: '',
        foto: null
    }

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const [loading, setLoading] = useState(true);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [listaOcorrencias, setListaOcorrencias] = useState([]);
    const [ocorrencia, setOcorrencia] = useState(emptyOcorrencia);
    const [listaStatus, setListaStatus] = useState([]);
    const dt = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-eye" className="p-button-rounded p-mr-2" onClick={() => this.teste(rowData)} /> */}
                <Button icon="pi pi-eye" onClick={() => mostrarOcorrencia(rowData)} />
            </React.Fragment>
        );
    }

    const mostrarOcorrencia = (ocorrencia) => {
        setLoading(true);
        const idOcorrencia = ocorrencia['idocorrencia'];
        api.get(`/ocorrencias/${idOcorrencia}`)
            .then(res => {
                setOcorrencia(res.data);
                setDisplayBasic(true);
                setLoading(false);
            });
    }

    const onHide = (name) => {
        if (name === 'displayBasic') {
            setDisplayBasic(false);
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Fechar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }

    const mostrarStatusOcorrencia = (value) => {
        setLoading(true);
        const idOcorrencia = value
        api.get(`/ocorrencias/status/${idOcorrencia}`)
            .then(res => {
                setListaStatus(res.data);
                setLoading(false);
            });
    }

    const itemTemplate = (item) => {
        return <img src={`data:image/png;base64,${item.foto}`} alt={item.alt} style={{ height: '100%', width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={`data:image/png;base64,${item.foto}`} alt={item.alt} style={{ display: 'block', width: '50px', height: '50px' }} />;
    }

    const indicatorTemplate = (index) => {
        return (
            <span className="indicator-text">
                {index + 1}
            </span>
        )
    }

    useEffect(() => {
        setLoading(true);
        api.get(`/ocorrencias/ultimas/${sessionStorage.getItem("idcliente")}`)
            .then(res => {
                setListaOcorrencias(res.data);
                setLoading(false);
            });
    }, []);

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.cor }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {
        return (
            <Card title={item.titulo} subTitle={item.subtitulo}
                style={{}}>
                {/* <img src={`data:image/png;base64,${item.foto}`}  height="300" width="300" ></img>                     */}
                <div>
                    <Galleria value={item.fotos} responsiveOptions={responsiveOptions} numVisible={5} circular
                        style={{ height: '10%', widht: '10%' }}
                        className="custom-indicator-galleria"
                        showIndicators changeItemOnIndicatorHover
                        showIndicatorsOnItem indicatorsPosition="bottom"
                        indicator={indicatorTemplate}
                        // showItemNavigators
                        showThumbnails={false}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <h6>{item.observacao}</h6>
                {/* <Button label="Read more" className="p-button-text"></Button> */}
            </Card>
        );
    };

    const fotoTemplate = (rowData) => {
        return <img src={`data:image/png;base64,${rowData.foto}`} alt={rowData.alt} style={{ display: 'block', width: '60px', height: '50px' }} />;
        // return (
        //     <React.Fragment>
        //         <Avatar image={`data:image/png;base64,${rowData.foto}`} className="p-mr-2" size="xlarge" shape="circle"/>
        //     </React.Fragment>
        // );
    }

    return (
        <>
            <DataTable ref={dt} dataKey="idocorrencia" value={listaOcorrencias} >
                <Column field="idocorrencia" header="N°" style={{ width: '20px', textAlign: 'center' }} ></Column>
                <Column body={fotoTemplate} header="Foto" style={{ width: '35px' }} ></Column>
                <Column field="area" header="Área" style={{ width: '50px' }}></Column>
                <Column field="ocorrencia" header="Descrição" style={{ width: '100px' }}></Column>
                <Column body={actionBodyTemplate} style={{ width: '15px', textAlign: 'right' }}></Column>
            </DataTable>

            <Dialog header={"Ocorrência " + ocorrencia.idocorrencia} visible={displayBasic}
                style={{ width: '70vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="text-500">Descrição
                    <h5> {ocorrencia.descricao} </h5>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Área
                        <h5> {ocorrencia.area} </h5>
                    </div>
                    <div className="p-col text-500">Referência de Localização
                        <h5> {ocorrencia.referencia} </h5>
                    </div>
                </div>


                <div className="p-grid">
                    <div className="p-col text-500">Status
                        <h5> {ocorrencia.status} </h5>
                    </div>
                    <div className="p-col text-500">Grau de Risco
                        <h5> {ocorrencia.gravidade} </h5>
                    </div>
                    <div className="p-col text-500">Qtd. Dias
                        <h5> {ocorrencia.qtddiasaberto} </h5>
                    </div>
                </div>


                <Accordion onTabOpen={() => mostrarStatusOcorrencia(ocorrencia.idocorrencia)}  >
                    <AccordionTab header="Requisitos Técnicos">
                    </AccordionTab>
                    <AccordionTab header="Linha do tempo">
                        <div className="timeline-demo">
                            <Timeline value={listaStatus} align="alternate"
                                className="customized-timeline" marker={customizedMarker}
                                content={customizedContent} />
                        </div>
                    </AccordionTab>
                </Accordion>
            </Dialog>
        </>
    )
}