import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import AppContentContext from '../../AppContentContext';
import api from '../../service/api';

export const GraficoAtividadeAreaPercentual = (props) => {

    const [grafico, setGrafico] = useState(null);
    const [loading, setLoading] = useState(true);

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            }
        },
        responsive: true
    };

    useEffect(() => {
        setLoading(true);
        api.get(`/atividade/percentual/dataini/${props.dataIni}/datafim/${props.dataFim}/id/${props.idCliente}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            });
    }, [props.dataIni, props.dataFim, props.idCliente]);

    return (
        <div>
            <AppContentContext.Consumer>
                {
                    context => {
                        // let options = context.darkTheme ? this.darkOptions : this.lightOptions;
                        let options = lightOptions;
                        return <Chart type="horizontalBar" height="400" data={grafico} options={options} />
                    }
                }
            </AppContentContext.Consumer>
        </div>
    )
}