import React from 'react';
import { HomeComponent } from './HomeComponent';


const PageIntermediaria = () => {
    return (
        <div>
            <HomeComponent />
        </div>

    )
}

export default PageIntermediaria;