import { Skeleton } from 'primereact/skeleton';
import React, { useState, useEffect } from 'react';
import { Card } from '../../components/card/Card';
import api from '../../service/api';

export const InfoInicialOcorrencias = () => {

    const emptyInfo = {
        AbertasHoje: null,
        Total: null,
        TotalMes: null
    }

    const [info, setInfo] = useState(emptyInfo);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/ocorrencias/infoinicial/id/${sessionStorage.getItem("idcliente")}`)
            .then(res => {
                setInfo(res.data);
                setLoading(false);
            })
    }, []);

    return (
        <>
            {!loading && (
                <div className="p-grid p-fluid dashboard">
                    <div className="p-col-12 p-md-6 p-xl-12">
                        <div className="highlight-box">
                            <div className="initials" style={{ backgroundColor: '#007be5', color: '#00448f' }}><span>TOTAL</span></div>
                            <div className="highlight-details ">
                                <i className="pi pi-th-large"></i>
                                <span>Total abertas</span>
                                <span className="count">{info.Total}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-12">
                        <div className="highlight-box">
                            <div className="initials" style={{ backgroundColor: '#ef6262', color: '#a83d3b' }}><span>MÊS</span></div>
                            <div className="highlight-details ">
                                <i className="pi pi-calendar"></i>
                                <span>Abertas este mês</span>
                                <span className="count">{info.TotalMes}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-12">
                        <div className="highlight-box">
                            <div className="initials" style={{ backgroundColor: '#20d077', color: '#038d4a' }}><span>HOJE</span></div>
                            <div className="highlight-details ">
                                <i className="pi pi-exclamation-triangle"></i>
                                <span>Abertas hoje</span>
                                <span className="count">{info.AbertasHoje}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && (
                <>
                    <div className="p-col-12 p-md-6 p-xl-12">
                        <Skeleton height="8rem" />
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-12">
                        <Skeleton height="8rem" />
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-12">
                        <Skeleton height="8rem" />
                    </div>
                </>
            )}             
        </>
    )
}