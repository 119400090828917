import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import AppContentContext from '../../AppContentContext';
import api from '../../service/api';
import { Card } from '../../components/card/Card';
import { Redirect } from 'react-router-dom';

export const GraficoTipoExecutor = (props) => {

    const emptyInfo = {
        EquipeLocal: null,
        EquipeSIMP: null
    }

    const [grafico, setGrafico] = useState(null);
    const [info, setInfo] = useState(emptyInfo);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [tipoClicado, setTipoClicado] = useState(null);
    const [descricaoTipoClicado, setDescricaoTipoClicado] = useState(null);
    const [loading, setLoading] = useState(true);

    const detalhes = (evt, item) => {
        if (item.length > 0) {
            setTipoClicado(grafico.Ids[item[0]._index]);
            setDescricaoTipoClicado(grafico.labels[item[0]._index]);
            setExibirDetalhes(true);
        }
    };

    const lightOptions = {
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        },
        responsive: true,
        hover: {
            onHover: function(e) {
               var point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
        'onClick': detalhes
    };

    useEffect(() => {
        async function getDados() {
            setLoading(true);
            let res1 = await api.get(`/tipoexecutor/dataini/${props.dataIni}/datafim/${props.dataFim}/id/${props.idCliente}`);
            let res2 = await api.get(`/tipoexecutor/cards/dataini/${props.dataIni}/datafim/${props.dataFim}/id/${props.idCliente}`);
            setGrafico(res1.data);
            setInfo(res2.data);
            setLoading(false);
        }
        getDados();
    }, [props.dataIni, props.dataFim, props.idCliente]);

    const clickCard = () => {
        setTipoClicado(1);
        setDescricaoTipoClicado('Equipe local');
        setExibirDetalhes(true);
    }

    if (exibirDetalhes) {
        return <Redirect push to={`/atividadestipoexecutorlist/${tipoClicado}/${descricaoTipoClicado}/${props.dataIni}/${props.dataFim}`} />
    }
    else {
        return (
            <div>
                <div className="p-grid p-mt-2 p-fluid dashboard">
                    <div className="p-col-12 p-lg-12">
                        <div className="card summary" onClick={clickCard} style={{cursor:'pointer'}}>
                            <span className="title" >Equipe Local</span>
                            <span className="detail" >Atividades da equipe local</span>
                            <span className="count visitors" >{info.EquipeLocal}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                </div>
                <div>
                    <Chart type="horizontalBar" height="200" data={grafico} options={lightOptions} />
                </div>
            </div>
        )
    }
}