import React, { useState } from 'react';
import { Calendar } from '../../components/calendar/Calendar';
import { Button } from '../../components/button/Button';
import { format } from "date-fns";
import { Toolbar } from '../../components/toolbar/Toolbar';
import { GraficoSistemaConstrutivo } from './SistemaConstrutivo';

export const DashboardSistemaConstrutivo = () => {

    const newdate = new Date(), y = newdate.getFullYear(), m = newdate.getMonth();

    const paramDatas = {
        dateIni: new Date(y, m, 1),
        dateFim: new Date(y, m + 1, 0)
    };

    const [datasAux, setDatasAux] = useState(paramDatas);
    const [datas, setDatas] = useState(paramDatas);

    const onChangeData = (e, name) => {
        let dt = { ...datasAux };
        dt[name] = e.value;
        setDatasAux(dt);
    }

    const btnClick = () => {
        setDatas(datasAux);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-fluid p-grid p-formgrid">
                    <div className=" p-col-8 p-md-4">
                        <Calendar id="dataini" tooltip="Data Inicial" tooltipOptions={{ position: 'top' }} dateFormat="dd/mm/yy"
                            value={datasAux.dateIni} onChange={(e) => onChangeData(e, 'dateIni')} showIcon />
                    </div>
                    <div className=" p-col-8 p-md-4">
                        <Calendar id="datafim" tooltip="Data Final" tooltipOptions={{ position: 'top' }} dateFormat="dd/mm/yy"
                            value={datasAux.dateFim} onChange={(e) => onChangeData(e, 'dateFim')} showIcon />
                    </div>
                </div>
            </React.Fragment>

        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label='Executar' onClick={btnClick} />
            </React.Fragment>
        )
    }

    const formatarData = (value) => {
        if (value) {
            let data = format(value, "dd-MM-yyyy");
            return data;
        }
        else {
            return 'estava vazaio';
        }
    }

    const getGrafico = (tipo) => {
        if ((datas.dateIni) && (datas.dateFim)) {
            if (tipo === "SistemaConstrutivo") {
                return (
                    <React.Fragment>
                        <GraficoSistemaConstrutivo dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} idCliente={sessionStorage.getItem("idcliente")} />
                    </React.Fragment>
                )
            }
        }
    }

    return (
        <div>
            <div className="content-section introduction">
                <h2>Atividades por Sistema Construtivo</h2>
            </div>

            <div className="content-section implementation">
                <div className="card">
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div>
                        {getGrafico('SistemaConstrutivo')}
                    </div>
                </div>
            </div>
        </div>
    )
}