import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { Accordion, AccordionTab } from '../../components/accordion/Accordion';
import { format, parseISO } from "date-fns";
import { Timeline } from '../../components/timeline/Timeline';
import { Card } from '../../components/card/Card';
import '../../csspersonalizado/timeline.css';
import '../../csspersonalizado/galleria.css';
import { Galleria } from '../../components/galleria/Galleria'
import api from '../../service/api';
import { InputText } from '../../components/inputtext/InputText';
import { Link } from 'react-router-dom';


export const OcorrenciasGravidadeList = (props) => {

    const emptyOcorrencia = {
        idocorrencia: 0,
        descricao: '',
        status: '',
        area: '',
        gravidade: '',
        referencia: '',
        qtddiasaberto: 0,
        recomendacaotecnica: '',
        enquadramentotecnico: ''
    }

    const [displayBasic, setDisplayBasic] = useState(false);
    const [listaOcorrencias, setListaOcorrencias] = useState([]);
    const [ocorrencia, setOcorrencia] = useState(emptyOcorrencia);
    const [listaStatus, setListaStatus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({ first: 0, rows: 10, page: 1, });

    // const statusOcorrencia =
    //     [
    //         { status2: 'Opaa', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
    //         { status2: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
    //         { status2: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
    //         { status2: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    //     ]

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    useEffect(() => {
        setLoading(true);
        api.get(`/ocorrencias/listar/idgravidade/${props.match.params.id}/idcliente/${sessionStorage.getItem("idcliente")}`)
            .then(res => {
                setListaOcorrencias(res.data);
                setTotalRecords(res.data.lenght);
                setLoading(false);
            });
    }, []);


    const mostrarOcorrencia = (name, position, pOcorrencia) => {
        setLoading(true);
        const idOcorrencia = pOcorrencia['idocorrencia']
        api.get(`/ocorrencias/${idOcorrencia}`)
            .then(res => {
                setOcorrencia(res.data);
                setDisplayBasic(true);
                setLoading(false);
            });
    }

    const onHide = (name) => {
        if (name === 'displayBasic'){
            setDisplayBasic(false);
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Fechar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-eye" className="p-button-rounded p-mr-2" onClick={() => this.teste(rowData)} /> */}
                {/* <Button tooltip="Detalhes" icon="pi pi-eye" onClick={() => mostrarOcorrencia('displayBasic', 'center', rowData)} /> */}
                <Link to={`/ocorrencia/${rowData[`idocorrencia`]}`}><Button icon="pi pi-eye" className="p-button p-mr-2" /></Link>
            </React.Fragment>
        );
    }

    const mostrarStatusOcorrencia = (value) => {
        setLoading(true);
        const idOcorrencia = value;
        api.get(`/ocorrencias/status/${idOcorrencia}`)
            .then(res => {
                setListaStatus(res.data);
                setLoading(false);
            });
    }

    const dateTemplate = (rowData) => {
        const Data = parseISO(rowData['datahora']);
        return format(Data, "dd/MM/yyyy HH:mm:ss");
    }

    const itemTemplate = (item) => {
        return <img src={`data:image/png;base64,${item.foto}`} alt={item.alt} style={{ height: '100%', width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={`data:image/png;base64,${item.foto}`} alt={item.alt} style={{ display: 'block', width: '50px', height: '50px' }} />;
    }

    const fotoTemplate = (rowData) => {
        return ( <img src={`data:image/png;base64,${rowData.foto}`} alt={rowData.alt} style={{ display: 'block', width: '60px', height: '50px' }} />
            // <React.Fragment>
            //     {!loadingFotos && (<img src={`data:image/png;base64,${rowData.foto}`} alt={rowData.alt} style={{ display: 'block', width: '60px', height: '50px' }} />)}
            //     {loadingFotos && <Skeleton width='60px' height='50px'/>  }
            // </React.Fragment>
        );
    }

    const indicatorTemplate = (index) => {
        return (
            <span className="indicator-text">
                {index + 1}
            </span>
        )
    }

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.cor }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const cardFooter = <span>
        {/* <Button label="Save" icon="pi pi-check" style={{ marginRight: '.25em' }} />
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary" /> */}
    </span>;

    const customizedContent = (item) => {
        return (

            <Card title={item.titulo} subTitle={item.subtitulo} footer={cardFooter}
                style={{}}>
                {/* <img src={`data:image/png;base64,${item.foto}`}  height="300" width="300" ></img>                     */}
                <div>
                    <Galleria value={item.fotos} responsiveOptions={responsiveOptions} numVisible={5} circular
                        style={{ height: '10%', widht: '10%' }}
                        className="custom-indicator-galleria"
                        showIndicators changeItemOnIndicatorHover
                        showIndicatorsOnItem indicatorsPosition="bottom"
                        indicator={indicatorTemplate}
                        // showItemNavigators
                        showThumbnails={false}
                        item={itemTemplate} thumbnail={thumbnailTemplate} />
                </div>
                <h6>{item.observacao}</h6>
                {/* <Button label="Read more" className="p-button-text"></Button> */}
            </Card>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">{`Gravidade: ${props.match.params.gravidade}`}</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    ); 

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    return (
        <div>
            <div className="content-section introduction">
                <h2>Ocorrências por Gravidade </h2>
            </div>
            <div className="p-grid crud-demo">
                <div className="p-grid crud-demo">
                    <div className="card">
                        <DataTable 
                            ref={dt} 
                            dataKey="idocorrencia" 
                            value={listaOcorrencias} 
                            paginator 
                            first={lazyParams.first} rows={10} totalRecords={totalRecords} onPage={onPage}
                            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                            onFilter={onFilter} filters={lazyParams.filters} loading={loading}
                            //rows={10} 
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} ocorrências"
                            className="datatable-responsive"
                            header={header}
                            globalFilter={globalFilter}>
                            <Column field="idocorrencia" header="Ocorrência" style={{ textAlign: 'center' }} ></Column>
                            <Column body={fotoTemplate} header="Foto" ></Column>
                            <Column field="area" header="Área" sortable style={{ width: '40%' }}></Column>
                            <Column field="status" header="Status" sortable style={{ width: '30%' }}></Column>
                            <Column body={actionBodyTemplate} style={{ width: '15%', textAlign: 'right' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            {/* <Dialog header={"Ocorrência " + ocorrencia.idocorrencia} visible={displayBasic}
                style={{ width: '70vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="text-500">Descrição
                    <h5> {ocorrencia.descricao} </h5>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Área
                        <h5> {ocorrencia.area} </h5>
                    </div>
                    <div className="p-col text-500">Referência de Localização
                        <h5> {ocorrencia.referencia} </h5>
                    </div>
                </div>


                <div className="p-grid">
                    <div className="p-col text-500">Status
                        <h5> {ocorrencia.status} </h5>
                    </div>
                    <div className="p-col text-500">Grau de Risco
                        <h5> {ocorrencia.gravidade} </h5>
                    </div>
                    <div className="p-col text-500">Qtd. Dias
                        <h5> {ocorrencia.qtddiasaberto} </h5>
                    </div>
                </div>

                <Accordion onTabOpen={() => mostrarStatusOcorrencia(ocorrencia.idocorrencia)}  >
                    <AccordionTab header="Requisitos Técnicos">
                    </AccordionTab>
                    <AccordionTab header="Linha do tempo">
                        <div className="timeline-demo">
                            <Timeline value={listaStatus} align="alternate"
                                className="customized-timeline" marker={customizedMarker}
                                content={customizedContent} />
                        </div>
                    </AccordionTab>
                </Accordion>
            </Dialog> */}
        </div>
    )
}

