import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import AppContentContext from '../../AppContentContext';
import api from '../../service/api';
import '../../csspersonalizado/container.css';
import { Redirect } from 'react-router';

export const GraficoOcorrenciasAreasDetalhes = (props) => {

    const [grafico, setGrafico] = useState(null);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [areaClicada, setAreaClicada] = useState(null);
    const [descricaoAreaClicada, setDescricaoAreaClicada] = useState(null);
    const [loading, setLoading] = useState(true);

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }

    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            }
        },
        responsive: true
    };

    const detalhes = (evt, item) => {
        // console.log('legend onClick', evt);
        // console.log(grafico);
        // console.log('Value item', grafico.datasets[0].data[item[0]._index]);
        // console.log('Desc item status', grafico.labels[item[0]._index]);
        // console.log('qtd ', grafico.QtdCA[item[0]._index]);
        // console.log('idca ', grafico.IdCA[item[0]._index]);
        if (item.length > 0) {
            setAreaClicada(grafico.Ids[item[0]._index]);
            setDescricaoAreaClicada(grafico.labels[item[0]._index]);
            setExibirDetalhes(true);            
        }
    };

    const optionExibirDetalhes = {
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        },
        responsive: true,
        'onClick': detalhes,
        maintainAspectRatio: false
    };

    useEffect(() => {
        setLoading(true);
        api.get(`/ocorrencias/grupoareadetalhes/id/${props.idCliente}/idarea/${props.idArea}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            })
    }, [props.idCliente, props.idArea]);

        if (exibirDetalhes) {
            return <Redirect push to={`/ocorrenciasareaslist/${areaClicada}/${descricaoAreaClicada}`} />
        }
        return (
            <div>
                <AppContentContext.Consumer>
                    {
                        context => {
                            // let options = context.darkTheme ? this.darkOptions : this.lightOptions;
                            let options = lightOptions;
                            return <Chart type="horizontalBar" data={grafico} options={optionExibirDetalhes}
                                // style={{height: '800px'}} 
                                height="500px"
                            />
                        }
                    }
                </AppContentContext.Consumer>
            </div>
        )
}