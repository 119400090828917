import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import AppContentContext from '../../AppContentContext';
import api from '../../service/api';
import { Redirect } from 'react-router';
import { Skeleton } from 'primereact/skeleton';

export const GraficoPrioridade = (props) => {

    const [grafico, setGrafico] = useState(null);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [prioridadeClicada, setPrioridadeClicada] = useState(null);
    const [descricaoPrioridadeClicada, setDescricaoPrioridadeClicada] = useState(null);
    const [loading, setLoading] = useState(true);

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            }
        },
        responsive: true
    };

    const detalhes = (evt, item) => {
        if (item.length > 0) {
            setPrioridadeClicada(grafico.Ids[item[0]._index]);
            setDescricaoPrioridadeClicada(grafico.labels[item[0]._index]);
            setExibirDetalhes(true);
        }
    };

    const optionDetalhes = {
        legend: {
            labels: {
                fontColor: '#495057'
            },
            position: 'bottom'
        },
        responsive: true,
        hover: {
            onHover: function(e) {
               var point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
        'onClick': detalhes
    };

    useEffect(() => {
        setLoading(true);
        api.get(`/prioridade/dataini/${props.dataIni}/datafim/${props.dataFim}/id/${props.idCliente}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            });
    }, [props.dataIni, props.dataFim, props.idCliente]);


    if (exibirDetalhes) {
        return <Redirect push to={`/atividadesprioridadelist/${prioridadeClicada}/${descricaoPrioridadeClicada}/${props.dataIni}/${props.dataFim}`} />
    }
    else {
        return (
            <>
                {!loading && (
                    <Chart type="doughnut" data={grafico} options={optionDetalhes} />
                )}
                {loading && (
                    <>
                        <div className="p-col-12 p-md-6 p-xl-12">
                            <Skeleton height="18rem" />
                        </div>
                    </>
                )}
            </>
        )
    }
}