import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../components/card/Card';
import { Panel } from '../components/panel/Panel';
import api from '../service/api';
import { Button } from '../components/button/Button';
import { format, parseISO } from "date-fns";
import { GraficoOcorrenciasGravidade } from './ocorrencias/ocorrenciasGravidade';
import { GraficoPrioridade } from './prioridade/prioridade';
import { ListaUltimasAtividades } from './atividade/ultimasAtividades'
import { ListaUltimasOcorrencias } from './ocorrencias/ultimasOcorrencias';
import { InfoInicialAtividades } from './atividade/infoInicial';
import { InfoInicialOcorrencias } from './ocorrencias/infoInicial';

export const HomeComponent = () => {

    const newdate = new Date(), y = newdate.getFullYear(), m = newdate.getMonth();

    const parametros = {
        dt1: new Date(y, m, 1),
        dt2: new Date(y, m + 1, 0)
    };

    const formatarData = (value) => {
        if (value) {
            let data = format(value, "dd-MM-yyyy");
            return data;
        }
        else {
            return 'estava vazaio';
        }
    }

    return (
        <div>
            <div className="home">
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-fixed" style={{ width: '160px' }}>
                                <h2> Ocorrências </h2>
                            </div>
                            <div className="p-col p-text-left">
                                <Link to='/ocorrencias'>
                                    <Button icon="pi pi-eye" />
                                </Link>
                            </div>
                        </div>
                        <InfoInicialOcorrencias />
                        <div className='p-mt-2'>
                            <Panel header='Gravidade'>
                                <GraficoOcorrenciasGravidade idCliente={sessionStorage.getItem("idcliente")} />
                            </Panel>
                        </div>
                        <div className='p-mt-3'>
                            <Panel header={'Últimas cadastradas'}>
                                <ListaUltimasOcorrencias />
                            </Panel>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-grid">
                            <div className="p-col-fixed" style={{ width: '140px' }}>
                                <h2> Atividades </h2>
                            </div>
                            <div className="p-col p-text-left">
                                <Link to='/atividadenovo'>
                                    <Button icon="pi pi-eye" />
                                </Link>
                            </div>
                        </div>
                        <InfoInicialAtividades />
                        <div className='p-mt-2'>
                            <Panel header={'Prioridade'}>
                                <GraficoPrioridade
                                    dataIni={formatarData(parametros.dt1)}
                                    dataFim={formatarData(parametros.dt2)}
                                    idCliente={sessionStorage.getItem("idcliente")} />
                            </Panel>
                        </div>
                        <div className='p-mt-3'>
                            <Panel header={'Últimas atualizações'} >
                                <ListaUltimasAtividades />
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
