import React, { useState, useEffect } from 'react';
import { Calendar } from '../../components/calendar/Calendar';
import { Button } from '../../components/button/Button';
import { format } from "date-fns";
import { Toolbar } from '../../components/toolbar/Toolbar';
import { GraficoAtividadeAreaPercentual } from './atividadeAreaPercentual';
import { Card } from '../../components/card/Card';
import api from '../../service/api';
import { Accordion, AccordionTab } from '../../components/accordion/Accordion';
import { GraficoPrioridade } from '../prioridade/prioridade';
import { GraficoTipoAtividade } from '../tipoatividade/TipoAtividade';
import { GraficoSistemaConstrutivo } from '../sistemaconstrutivo/SistemaConstrutivo';
import { GraficoTipoExecutor } from '../tipoexecutor/TipoExecutor';
import LoadingScreen from 'react-loading-screen';
import { CardsAtividade } from './cardsAtividade';
import { Panel } from '../../components/panel/Panel';

export const DashboardAtividadeNovo = () => {

    const newdate = new Date(), y = newdate.getFullYear(), m = newdate.getMonth();

    const paramDatas = {
        dateIni: new Date(y, m, 1),
        dateFim: new Date(y, m + 1, 0)
    };

    const emptyInfo = {
        Finalizadas: null,
        Ressalva: null,
        Adiadas: null,
        NaoExecutada: null,
        Total: null
    };

    const [datasAux, setDatasAux] = useState(paramDatas);
    const [datas, setDatas] = useState(paramDatas);
    const [info, setInfo] = useState(emptyInfo);
    const [loading, setLoading] = useState(true);

    const onChangeData = (e, name) => {
        let dt = { ...datasAux };
        dt[name] = e.value;
        setDatasAux(dt);
    }

    const btnClick = () => {
        setDatas(datasAux);
    }

    const formatarData = (value) => {
        if (value) {
            let data = format(value, "dd-MM-yyyy");
            return data;
        }
        else {
            return 'estava vazaio';
        }
    }

    useEffect(() => {
        setLoading(true);
        api.get(`/atividade/info/dataini/${formatarData(datas.dateIni)}/datafim/${formatarData(datas.dateFim)}/id/${sessionStorage.getItem("idcliente")}`)
            .then(res => {
                setInfo(res.data);
                setLoading(false);
            });
    }, [datas.dateIni, datas.dateFim]);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-fluid p-grid p-formgrid">
                    <div className=" p-col-8 p-md-4">
                        <Calendar id="dataini" tooltip="Data Inicial" tooltipOptions={{ position: 'top' }} dateFormat="dd/mm/yy"
                            value={datasAux.dateIni} onChange={(e) => onChangeData(e, 'dateIni')} showIcon />
                    </div>
                    <div className=" p-col-8 p-md-4">
                        <Calendar id="datafim" tooltip="Data Final" tooltipOptions={{ position: 'top' }} dateFormat="dd/mm/yy"
                            value={datasAux.dateFim} onChange={(e) => onChangeData(e, 'dateFim')} showIcon />
                    </div>
                </div>
            </React.Fragment>

        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label='Executar' onClick={btnClick} />
            </React.Fragment>
        )
    }

    const getGrafico = (tipo) => {
        if ((datas.dateIni) && (datas.dateFim)) {
            if (tipo === "Atividade") {
                return (
                    <React.Fragment>
                        <GraficoAtividadeAreaPercentual dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} idCliente={sessionStorage.getItem("idcliente")} />
                    </React.Fragment>
                )
            }
            if (tipo === "Prioridade") {
                return (
                    <React.Fragment>
                        <GraficoPrioridade dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} idCliente={sessionStorage.getItem("idcliente")} />
                    </React.Fragment>
                )
            }
            if (tipo === "TipoAtividade") {
                return (
                    <React.Fragment>
                        <GraficoTipoAtividade dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} idCliente={sessionStorage.getItem("idcliente")} />
                    </React.Fragment>
                )
            }
            if (tipo === "SistemaConstrutivo") {
                return (
                    <React.Fragment>
                        <GraficoSistemaConstrutivo dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} idCliente={sessionStorage.getItem("idcliente")} />
                    </React.Fragment>
                )
            }
            if (tipo === "TipoExecutor") {
                return (
                    <React.Fragment>
                        <GraficoTipoExecutor dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} idCliente={sessionStorage.getItem("idcliente")} />
                    </React.Fragment>
                )
            }
        }
    }

    return (
        <div>
            <div className="content-section introduction">
                <h2>Atividades</h2>
                <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </div>
            <div className="p-grid p-fluid dashboard">
                <CardsAtividade dataIni={formatarData(datas.dateIni)} dataFim={formatarData(datas.dateFim)} />
            </div>
            <div>
                <br></br>
                <Accordion>
                    <AccordionTab header="Detalhes (Percentual de atividades executadas por área)">
                        {getGrafico('Atividade')}
                    </AccordionTab>

                </Accordion>
                <br></br>
                <div className="p-grid">
                    <div className="p-col-6">
                        <Panel header={'Prioridade'}>
                            {getGrafico('Prioridade')}
                        </Panel>
                    </div>
                    <div className="p-col-6">
                        <Panel header={'Tipo de Atividade'}>
                            {getGrafico('TipoAtividade')}
                        </Panel>
                    </div>
                </div>
                <br></br>
                <div className="p-grid">
                    <div className="p-col-6">
                        <Panel header={'Sistema Construtivo'}>
                            {getGrafico('SistemaConstrutivo')}
                        </Panel>
                    </div>
                    <div className="p-col-6">
                        <Panel header={'Tipo de Executor'}>
                            {getGrafico('TipoExecutor')}
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    )
}