import React, {useState, createContext} from 'react';

export const UserContext = createContext();

const UserProvider = ({ children }) => {

    const [data, setData] = useState({
        logger:  (sessionStorage.getItem('simp-cli-token') && !sessionStorage.getItem('trocarsenha'))
    });

    
    const isLogger = data.logger;

    const alterLogin = value => {
        setData({...data, logger : value})
    }

    const [idUsaurio, setIdUsuario] = useState(0);
    const [idCliente, setIdCliente] = useState(parseInt(sessionStorage.getItem('idcliente')));
    const [clientes, setClientes] = useState([]);


    return (
        <UserContext.Provider value={{
            isLogger,
            alterLogin,
            idUsaurio,
            setIdUsuario,
            clientes,
            setClientes,
            idCliente, 
            setIdCliente,
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;
