import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import AppContentContext from '../../AppContentContext';
import api from '../../service/api';
import { Redirect } from 'react-router';

export const GraficoTipoAtividade = (props) => {

    const [grafico, setGrafico] = useState(null);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [tipoAtividadeClicada, setTipoAtividadeClicada] = useState(null);
    const [descricaoTipoAtividadeClicada, setDescricaoTipoAtividadeClicada] = useState(null);
    const [loading, setLoading] = useState(true);

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            }
        },
        responsive: true
    };

    const detalhes = (evt, item) => {
        if (item.length > 0) {
            setTipoAtividadeClicada(grafico.Ids[item[0]._index]);
            setDescricaoTipoAtividadeClicada(grafico.labels[item[0]._index]);
            setExibirDetalhes(true);            
        }
    };

    const optionExibirDetalhes = {
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        },
        hover: {
            onHover: function(e) {
               var point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
        responsive: true,
        'onClick': detalhes
    };

    useEffect(() => {
        setLoading(true);
        api.get(`/tipoatividade/dataini/${props.dataIni}/datafim/${props.dataFim}/id/${props.idCliente}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            });
    }, [props.dataIni, props.dataFim, props.idCliente]);

    if (exibirDetalhes) {
        return <Redirect push to={`/atividadestipoatividadelist/${tipoAtividadeClicada}/${descricaoTipoAtividadeClicada}/${props.dataIni}/${props.dataFim}`} />
    }
    else
        return (
            <div>
                <AppContentContext.Consumer>
                    {
                        context => {
                            let options = lightOptions;
                            return <Chart type="horizontalBar" data={grafico} options={optionExibirDetalhes} />
                        }
                    }
                </AppContentContext.Consumer>
            </div>
        )
}