import React, { useState, useEffect, useRef } from 'react';
import api from '../../service/api';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from '../../components/column/Column';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { InputText } from '../../components/inputtext/InputText';

export const TipoExecutorList = (props) => {

    const emptyAtividade = {
        prioridade: '',
        idprioridade: 0,
        idclienteplanoitens: 0,
        atividade: '',
        item: '',
        area: '',
        datalimite: '',
        tipoatividade: '',
        tipoexecutor: '',
        execucao: '',
        undperiodicidade: '',
    }

    const [listaAtividades, setListaAtividades] = useState([]);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [atividade, setAtividade] = useState(emptyAtividade);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    useEffect(() => {
        setLoading(true);
        const { id, dataini, datafim } = props.match.params;
        api.get(`/tipoexecutor/atividades/dataini/${dataini}/datafim/${datafim}/idcliente/${sessionStorage.getItem("idcliente")}/idtipo/${id}`)
            .then(res => {
                setListaAtividades(res.data);
                setLoading(false);
            });
    }, []);

    const mostrarAtividade = (atividade) => {
        setLoading(true);
        const idAtividade = atividade['idclienteplanoitens']
        api.get(`/atividade/${idAtividade}`)
            .then(res => {
                setAtividade(res.data);
                setDisplayBasic(true);
                setLoading(false);
            });
    }

    const onHide = (name) => {
        if (name === 'displayBasic') {
            setDisplayBasic(false);
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Fechar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button tooltip={'Detalhes'} icon="pi pi-eye" onClick={() => mostrarAtividade(rowData)} />
            </React.Fragment>
        );
    }

    const actionBodyTemplateExecucao = (rowData) => {
        switch (rowData.execucao) {
            case 0: return (
                <React.Fragment>
                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} position="top" />
                    <img className="logo" alt="logo" src={`assets/simp/question.png`}
                        data-pr-tooltip={(rowData.tipoexecucao)} width="35px" />
                </React.Fragment>
            );
            case 1: return (
                <React.Fragment>
                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} position="top" />
                    <img className="logo" alt="logo" src={`assets/simp/Icon-logo-Confirmar.png`}
                        data-pr-tooltip={(rowData.tipoexecucao)} width="35px" />
                </React.Fragment>
            );
            case 2: return (
                <React.Fragment>
                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} position="top" />
                    <img className="logo" alt="logo" src={`assets/simp/Icon-logo-Alerta.png`}
                        data-pr-tooltip={(rowData.tipoexecucao)} width="35px" />
                </React.Fragment>
            );
            case 3: return (
                <React.Fragment>
                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} position="top" />
                    <img className="logo" alt="logo" src={`assets/simp/Icon-logo-adiar.png`}
                        data-pr-tooltip={(rowData.tipoexecucao)} width="35px" />
                </React.Fragment>
            );
            case 4: return (
                <React.Fragment>
                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} position="top" />
                    <img className="logo" alt="logo" src={`assets/simp/Icon-logo-cancelar.png`}
                        data-pr-tooltip={(rowData.tipoexecucao)} width="35px" />
                </React.Fragment>
            );
        }
    }

    // const dateFilter = <Calendar value={selectedDate} onChange={onDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="Registration Date"/>;

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">{`Executor: ${props.match.params.tipo}`}</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );    
    
    return (
        <div>
            <div className="content-section introduction">
                <h2>Atividades por Executor </h2>
            </div>
            <div className="content-section implementation">
                <div className="p-grid crud-demo">
                    <div className="card">
                        <DataTable 
                            ref={dt} 
                            dataKey="idclienteplanoitens" 
                            value={listaAtividades} 
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} ocorrências"
                            header={header} 
                            globalFilter={globalFilter}>
                            <Column field="idclienteplanoitens" header="Atividade" sortable style={{ width: '120px', textAlign: 'left' }} ></Column>
                            <Column field="area" header="Área" sortable ></Column>
                            <Column field="item" header="Item" sortable ></Column>
                            <Column field="datalimite" header="Data Limite" sortable style={{  width: '120px', textAlign: 'center' }}></Column>
                            <Column field="execucao" body={actionBodyTemplateExecucao} style={{ width: '45px', textAlign: 'center' }}></Column>
                            <Column body={actionBodyTemplate} style={{ width: '45px', textAlign: 'center' }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog header={"Atividade " + atividade.idclienteplanoitens} visible={displayBasic}
                style={{ width: '70vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="text-500">Atividade
                    <h5> {atividade.atividade} </h5>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Prioridade
                        <h5> {atividade.prioridade} </h5>
                    </div>
                    <div className="p-col text-500">Data Limite
                        <h5> {atividade.datalimite} </h5>
                    </div>
                    <div className="p-col text-500">Execução
                        <h5> {atividade.execucao} </h5>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Área
                        <h5> {atividade.area} </h5>
                    </div>
                    <div className="p-col text-500">Item
                        <h5> {atividade.item} </h5>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col text-500">Tipo de Atividade
                        <h5> {atividade.tipoatividade} </h5>
                    </div>
                    <div className="p-col text-500">Periodicidade
                        <h5> {atividade.undperiodicidade} </h5>
                    </div>
                    <div className="p-col text-500">Responsável
                        <h5> {atividade.tipoexecutor} </h5>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}