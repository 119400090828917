import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Button } from '../../components/button/Button';
import { Dialog } from '../../components/dialog/Dialog';
import { Accordion, AccordionTab } from '../../components/accordion/Accordion';
import { format, parseISO } from "date-fns";
import { Timeline } from '../../components/timeline/Timeline';
import { Card } from '../../components/card/Card';
import '../../csspersonalizado/timeline.css';
import '../../csspersonalizado/galleria.css';
import { Galleria } from '../../components/galleria/Galleria'
import api from '../../service/api';
import { InputText } from '../../components/inputtext/InputText';
import { Toast } from '../../components/toast/Toast';
import { InputTextarea } from '../../components/inputtextarea/InputTextarea';
import { Dropdown } from '../../components/dropdown/Dropdown';
import { Panel } from '../../components/panel/Panel';
import { TabView, TabPanel } from '../../components/tabview/TabView';
import { useHistory } from "react-router-dom";
import { SplitButton } from '../../components/splitbutton/SplitButton';
import { OcorrenciaEditImagens } from './ocorrenciasEditImagens';
import { OcorrenciasAnexos } from './ocorrenciasAnexos';


export const Ocorrencia = (props) => {

    const emptyOcorrencia = {
        idocorrencia: 0,
        descricao: '',
        idclientesareas: null,
        area: '',
        idgravidade: null,
        gravidade: '',
        referencia: '',
        recomendacoestecnicas: '',
        enquadramentostecnicos: '',
        idclienteplanoitenschecklist: null,
        idclientesareassistemaconstrutivoitens: null,
        item: '',
        idstatus: null,
        status: '',
        qtddiasaberto: 0
    };

    const emptyOcorrenciaStatus = {
        idocorrenciasstatus: null,
        idocorrencia: null,
        datahora: null,
        idclientesusuarios: null,
        observacao: '',
        idtipostatus: null
    };

    const history = useHistory();

    const [ocorrencia, setOcorrencia] = useState(emptyOcorrencia);
    const [ocorrenciaStatus, setOcorrenciaStatus] = useState(emptyOcorrenciaStatus);
    const [listaStatusOcorrencia, setListaStatusOcorrencia] = useState([]);
    const [listaGravidade, setListaGravidade] = useState([]);
    const [listaItensArea, setListaItensarea] = useState([]);
    const [listaStatus, setListastatus] = useState([]);
    const [clienteUsuario, setClienteUsuario] = useState({});
    const [displayDialogNovoStatus, setDisplayDialogNovoStatus] = useState(false);
    const [displayDialogEditarStatus, setDisplayDialogEditarStatus] = useState(false);
    const [displayDialogFotosStatus, setDisplayDialogFotosStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const getOcorrenciaStatusAtual = () =>{
        api.get(`/ocorrenciasstatus/atual/${ocorrencia.idocorrencia}`)
        .then((res) => {
            setOcorrenciaStatus(res.data);
        });
    }

    const btnStatus = [
        {
            label: 'Novo',
            icon: 'pi pi-arrow-right',
            command: () => {
                setOcorrenciaStatus(emptyOcorrenciaStatus);
                api.get(`/tipostatusnavegacao/status/${ocorrencia.idstatus}`)
                    .then((res) => {
                        setListastatus(res.data);
                        console.log(res.data);
                    })
                setDisplayDialogNovoStatus(true);
            }
        },
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => {
                getOcorrenciaStatusAtual();
                setDisplayDialogEditarStatus(true);
            }
        }
    ];

    const dialogFuncMap = {
        'displayNovoStatus': setDisplayDialogNovoStatus,
        'displayEditarStatus': setDisplayDialogEditarStatus,
        'displayFotosStatus': setDisplayDialogFotosStatus,
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    useEffect(() => {
        const getDados = async () => {
            const _listaGravidade = await api.get('/gravidade');
            const _ocorrencia = await api.get(`/ocorrencias/${props.match.params.id}`);
            const _listaItensArea = await api.get(`/clientesareassistemaconstrutivoitens/clientesareas/${_ocorrencia.data.idclientesareas}`);
            setListaGravidade(_listaGravidade.data);
            setOcorrencia(_ocorrencia.data);
            setListaItensarea(_listaItensArea.data);
            setLoading(false);
        };
        setLoading(true);
        api.get(`/clientesusuarios/usuario/${sessionStorage.getItem('idusuario')}/cliente/${sessionStorage.getItem('idcliente')}`)
            .then((res) => {
                setClienteUsuario(res.data);
            })
        getDados();
    }, [props.match.params.id]);

    const mostrarStatusOcorrencia = (value) => {
        setLoading(true);
        const idOcorrencia = value
        api.get(`/ocorrencias/status/${idOcorrencia}`)
            .then(res => {
                setListaStatusOcorrencia(res.data);
                setLoading(false);
            });
    }

    // const dateTemplate = (rowData) => {
    //     const Data = parseISO(rowData['datahora']);
    //     ; return format(Data, "dd/MM/yyyy HH:mm:ss");
    // }

    const itemTemplate = (item) => {
        return <img src={`data:image/png;base64,${item.foto}`} alt={item.alt} style={{ height: '100%', width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={`data:image/png;base64,${item.foto}`} alt={item.alt} style={{ display: 'block', width: '50px', height: '50px' }} />;
    }

    // const fotoTemplate = (rowData) => {
    //     return (<img src={`data:image/png;base64,${rowData.foto}`} alt={rowData.alt} style={{ display: 'block', width: '60px', height: '50px' }} />
    //         // <React.Fragment>
    //         //     {!loadingFotos && (<img src={`data:image/png;base64,${rowData.foto}`} alt={rowData.alt} style={{ display: 'block', width: '60px', height: '50px' }} />)}
    //         //     {loadingFotos && <Skeleton width='60px' height='50px'/>  }
    //         // </React.Fragment>
    //     );
    // }

    const indicatorTemplate = (index) => {
        return (
            <span className="indicator-text">
                {index + 1}
            </span>
        )
    }

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.cor }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const cardFooter = <span>
        {/* <Button label="Save" icon="pi pi-check" style={{ marginRight: '.25em' }} />
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary" /> */}
    </span>;

    const customizedContent = (item) => {
        return (

            <Card title={item.titulo} subTitle={item.subtitulo} footer={cardFooter}
                style={{}}>
                {/* <img src={`data:image/png;base64,${item.foto}`}  height="300" width="300" ></img>                     */}
                <div>
                    <Galleria value={item.fotos} responsiveOptions={responsiveOptions} numVisible={5} circular
                        style={{ height: '10%', widht: '10%' }}
                        className="custom-indicator-galleria"
                        showIndicators changeItemOnIndicatorHover
                        showIndicatorsOnItem indicatorsPosition="bottom"
                        indicator={indicatorTemplate}
                        // showItemNavigators
                        showThumbnails={false}
                        item={itemTemplate} 
                        thumbnail={thumbnailTemplate} />
                </div>
                <h6>{item.observacao}</h6>
                {/* <Button label="Read more" className="p-button-text"></Button> */}
            </Card>
        );
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _ocorrencia = { ...ocorrencia };
        _ocorrencia[`${name}`] = val;
        setOcorrencia(_ocorrencia);
    }

    const onInputChangeStatus = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _ocorrenciaStatus = { ...ocorrenciaStatus };
        _ocorrenciaStatus[`${name}`] = val;
        setOcorrenciaStatus(_ocorrenciaStatus);
    }

    const onGravidadeChange = (e) => {
        let _ocorrencia = { ...ocorrencia };
        _ocorrencia.idgravidade = e.value;
        setOcorrencia(_ocorrencia);
    }

    const onItemChange = (e) => {
        let _ocorrencia = { ...ocorrencia };
        _ocorrencia.idclientesareassistemaconstrutivoitens = e.value;
        setOcorrencia(_ocorrencia);
    }

    const onStatusChange = (e) => {
        let _ocorrenciaStatus = { ...ocorrenciaStatus };
        _ocorrenciaStatus.idtipostatus = e.value;
        setOcorrenciaStatus(_ocorrenciaStatus);
    }

    const salvar = () => {
        setSubmitted(true);
        if (ocorrencia.descricao && ocorrencia.idclientesareassistemaconstrutivoitens && ocorrencia.idgravidade) {

            let _ocorrencia = { ...ocorrencia };
            if (_ocorrencia.idocorrencia) {
                api.put('/ocorrencias', _ocorrencia)
                    .then(res => {
                        if (res.status === 200) {
                            setSubmitted(false);
                            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                        }
                    })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            };
        }
    }

    const findIndexById = (n) => {
        console.log(listaStatus);
        let index = -1;
        for (let i = 0; i < listaStatus.length; i++) {
            if (listaStatus[i].idtipostatusnavegacao === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const salvarStatus = () => {
        let _ocorrenciaStatus = { ...ocorrenciaStatus };
        if (_ocorrenciaStatus.idocorrenciasstatus) {
            api.put('/ocorrenciasstatus', _ocorrenciaStatus)
                .then(res => {
                    if (res.status === 200) {
                        setSubmitted(false);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                });
        }
        else {
            _ocorrenciaStatus.idocorrenciasstatus = -1;
            _ocorrenciaStatus.idocorrencia = ocorrencia.idocorrencia;
            _ocorrenciaStatus.idclientesusuarios = clienteUsuario.idclientesusuarios;
            api.post('/ocorrenciasstatus', _ocorrenciaStatus)
                .then(res => {
                    if (res.status === 200) {
                        setSubmitted(false);
                        let _ocorrencia = { ...ocorrencia };
                        let id = findIndexById(_ocorrenciaStatus.idtipostatus);
                        _ocorrencia.idstatus = _ocorrenciaStatus.idtipostatus;
                        _ocorrencia.status = listaStatus[id].tipostatus;
                        _ocorrencia.qtddiasaberto = 0;
                        setOcorrencia(_ocorrencia);
                        setDisplayDialogNovoStatus(false);
                        fotoClick();
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                });
        }
    };

    const footerDialogNovoStatus = () => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide('displayNovoStatus')} className="p-button-text" />
                <Button label="Salvar" icon="pi pi-check" onClick={salvarStatus} autoFocus className="p-button-success"/>
            </div>
        );
    }

    const footerDialogEditarStatus = () => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide('displayEditarStatus')} className="p-button-text" />
                <Button label="Salvar" icon="pi pi-check" onClick={salvarStatus} autoFocus className="p-button-success" />
            </div>
        );
    }

    const fotoClick = () =>{
        getOcorrenciaStatusAtual();
        setDisplayDialogFotosStatus(true);
    }

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="content-section introduction">
                    <h5>{sessionStorage.getItem('cliente')}</h5>
                </div>

                <div className=" p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Panel header={`Ocorrência - ${ocorrencia.idocorrencia}`} toggleable className='p-mb-3'>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12" >
                                    <label htmlFor="descricao">Descrição</label>
                                    <InputTextarea id="descricao" value={ocorrencia.descricao} onChange={(e) => onInputChange(e, 'descricao')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !ocorrencia.descricao })}
                                        onFocus={(e) => e.target.select()} />
                                    {submitted && !ocorrencia.descricao && <small className="p-invalid">A descrição da ocorrência é requerida.</small>}
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="area">Área</label>
                                    <InputText id="area" value={ocorrencia.area} onChange={(e) => onInputChange(e, 'area')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !ocorrencia.area })}
                                        onFocus={(e) => e.target.select()} disabled={true} />
                                    {submitted && !ocorrencia.area && <small className="p-invalid">A área da ocorrência é requerida.</small>}
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="idclientesareassistemaconstrutivoitens">Item</label>
                                    <Dropdown
                                        inputId="idclientesareassistemaconstrutivoitens"
                                        optionValue="idclientesareassistemaconstrutivoitens"
                                        optionLabel="descricao"
                                        value={ocorrencia.idclientesareassistemaconstrutivoitens}
                                        options={listaItensArea}
                                        onChange={(e) => onItemChange(e)}
                                        placeholder="Selecione"
                                        required
                                        filter showClear filterBy="descricao"
                                        className={classNames({ 'p-invalid': submitted && !ocorrencia.idclientesareassistemaconstrutivoitens })} />
                                    {submitted && !ocorrencia.idclientesareassistemaconstrutivoitens && <small className="p-invalid">O item é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="referencia">Referência</label>
                                    <InputText id="referencia" value={ocorrencia.referencia} onChange={(e) => onInputChange(e, 'referencia')}
                                        autoFocus onFocus={(e) => e.target.select()} />
                                </div>
                                <div className="p-field p-col-6" >
                                    <label htmlFor="status">Status Atual</label>
                                    <InputText id="status" value={ocorrencia.status} onChange={(e) => onInputChange(e, 'status')}
                                        autoFocus onFocus={(e) => e.target.select()} disabled={true} />
                                </div>
                                <div className="p-field p-col-4" >
                                    <label htmlFor="idgravidade">Grau de Risco</label>
                                    <Dropdown inputId="idgravidade" optionValue="idgravidade" optionLabel="gravidade" value={ocorrencia.idgravidade} options={listaGravidade}
                                        onChange={(e) => onGravidadeChange(e)} placeholder="Selecione"
                                        required className={classNames({ 'p-invalid': submitted && !ocorrencia.idgravidade })} />
                                    {submitted && !ocorrencia.idgravidade && <small className="p-invalid">O grau de risco é requerido.</small>}
                                </div>
                                <div className="p-field p-col-2" >
                                    <label htmlFor="qtddiasaberto">Qtd. dias</label>
                                    <InputText id="qtddiasaberto" value={ocorrencia.qtddiasaberto} onChange={(e) => onInputChange(e, 'qtddiasaberto')}
                                        autoFocus onFocus={(e) => e.target.select()} disabled={true} />
                                </div>
                                <div className="p-col-12" >
                                    <TabView>
                                        <TabPanel header="Recomendações Técnicas">
                                            <InputTextarea id="recomendacoestecnicas" value={ocorrencia.recomendacoestecnicas} 
                                                // onChange={(e) => onInputChange(e, 'recomendacoestecnicas')}
                                                autoFocus onFocus={(e) => e.target.select()} style={{ height: '200px' }} />
                                        </TabPanel>
                                        <TabPanel header="Enquadramento Técnico">
                                            <InputTextarea id="enquadramentostecnicos" value={ocorrencia.enquadramentostecnicos} 
                                                // onChange={(e) => onInputChange(e, 'enquadramentostecnicos')}
                                                autoFocus onFocus={(e) => e.target.select()} style={{ height: '200px' }} />
                                        </TabPanel>
                                        <TabPanel header="Anexos">
                                            <OcorrenciasAnexos id={props.match.params.id} />
                                        </TabPanel>
                                    </TabView>
                                </div>
                            </div>
                            <div className="p-d-flex p-jc-between">
                                <div className="p-col-6 p-mr-2">
                                    <SplitButton label="Status" type="button" icon="pi pi-tags" model={btnStatus} className="p-mr-2" />
                                    <Button label="Fotos" type="button" icon="pi pi-camera" onClick={fotoClick}/>
                                </div>
                                <div className="p-d-flex p-justify-end p-col-6">
                                    <Button label="Voltar" type="button" icon="pi pi-arrow-left" className="p-button-warning p-mr-2" onClick={history.goBack} />
                                    <Button label="Salvar" type="button" icon="pi pi-check" className="p-button-success" onClick={salvar} />
                                </div>
                            </div>
                        </Panel>
                        <Accordion onTabOpen={() => mostrarStatusOcorrencia(ocorrencia.idocorrencia)}  >
                            <AccordionTab header="Linha do tempo">
                                <div className="timeline-demo">
                                    <Timeline 
                                        value={listaStatusOcorrencia} 
                                        align="alternate"
                                        className="customized-timeline" 
                                        marker={customizedMarker}
                                        content={customizedContent} />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
            <Dialog header="Novo status" visible={displayDialogNovoStatus} style={{ width: '50vw' }} footer={footerDialogNovoStatus} onHide={() => onHide('displayNovoStatus')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12" >
                        <label htmlFor="novostatus">Status</label>
                        <Dropdown
                            inputId="novostatus"
                            optionValue="idtipostatusnavegacao"
                            optionLabel="tipostatus"
                            value={ocorrenciaStatus.idtipostatus}
                            options={listaStatus}
                            onChange={(e) => onStatusChange(e)}
                            placeholder="Selecione"
                            required filter showClear filterBy="tipostatus"
                            className={classNames({ 'p-invalid': submitted && !ocorrenciaStatus.idtipostatus })} />
                        {submitted && !ocorrenciaStatus.idtipostatus && <small className="p-invalid">O status é requerido.</small>}
                    </div>
                    <div className="p-field p-col-12" >
                        <label htmlFor="observacao">Observação</label>
                        <InputTextarea id="observacao" value={ocorrenciaStatus.observacao} onChange={(e) => onInputChangeStatus(e, 'observacao')}
                            autoFocus onFocus={(e) => e.target.select()} style={{ height: "200px" }} />
                    </div>
                </div>
            </Dialog>
            <Dialog header="Editar status atual" visible={displayDialogEditarStatus} style={{ width: '63vw' }} footer={footerDialogEditarStatus} onHide={() => onHide('displayEditarStatus')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12" >
                        <label htmlFor="statusatual">Status Atual</label>
                        <InputText id="statusatual" value={ocorrencia.status}
                            autoFocus onFocus={(e) => e.target.select()} disabled={true} />
                    </div>
                    <div className="p-field p-col-12" >
                        <label htmlFor="observacao">Observação</label>
                        <InputTextarea id="observacao" value={ocorrenciaStatus.observacao} onChange={(e) => onInputChangeStatus(e, 'observacao')}
                            autoFocus onFocus={(e) => e.target.select()} style={{ height: "150px" }} />
                    </div>
                </div>
            </Dialog>
            <Dialog header="Fotos" visible={displayDialogFotosStatus} style={{ width: '63vw' }} onHide={() => onHide('displayFotosStatus')}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12" >
                        <label htmlFor="statusatual">Status Atual</label>
                        <InputText id="statusatual" value={ocorrencia.status}
                            autoFocus onFocus={(e) => e.target.select()} disabled={true} />
                    </div>
                    <div className="p-field p-col-12" >
                        <OcorrenciaEditImagens idocorrenciasstatus={ocorrenciaStatus.idocorrenciasstatus} />
                    </div>
                </div>
            </Dialog>            
        </>
    )
}

