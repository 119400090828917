import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { DashboardPrioridade } from '../src/view/prioridade/dashboardprioridade';
import { testeGrafico } from '../src/view/teste/teste';
import { DashboardTipoAtividade } from '../src/view/tipoatividade/dashboardtipoatividade';
import { DashboardTipoExecutor } from '../src/view/tipoexecutor/dashboardTipoExecutor';
import { DashboardSistemaConstrutivo } from '../src/view/sistemaconstrutivo/dashboardsistemaconstrutivo';
import { DashboardAtividade } from '../src/view/atividade/dashboardatividade';
import { DashboardOcorrencias } from '../src/view/ocorrencias/dashboardOcorrencias';
import PrivateRoute from './privateRoute';
import { DashboardAtividadeNovo } from '../src/view/atividade/dashboardAtividadeNovo';
import { OcorrenciasGravidadeList } from '../src/view/ocorrencias/ocorrenciasGravidadeList';
import { OcorrenciasStatusList } from '../src/view/ocorrencias/ocorrenciasStatusList';
import { OcorrenciasAreasList } from './view/ocorrencias/ocorrenciasAreasList';
import { AtividadesPrioridadeList } from './view/prioridade/prioridadeList';
import { AtividadesTipoAtividadeList } from './view/tipoatividade/tipoAtividadeList';
import { OcorrenciasAreasDetalhesList } from './view/ocorrencias/ocorrenciasAreasDetalhesList';
import  PageIntermediaria  from './view/pageIntermediaria';
import Login from './view/login/login';
import { TrocarSenha } from './view/login/trocarSenha';
import { SistemaConstrutivoList } from './view/sistemaconstrutivo/sistemaConstrutivoList';
import { TipoExecutorList } from './view/tipoexecutor/tipoExecutorList';
import { Ocorrencia } from './view/ocorrencias/ocorrencia';

const AppRouter = () => {


    return (
        <>
            <Switch>
                <PrivateRoute exact path="/" component={PageIntermediaria} />
                <PrivateRoute path="/prioridade" component={DashboardPrioridade} />
                <Route path="/teste" component={testeGrafico} />
                <PrivateRoute path="/tipoatividade" component={DashboardTipoAtividade} />
                <PrivateRoute path="/tipoexecutor" component={DashboardTipoExecutor} />
                <PrivateRoute path="/sistemaconstrutivo" component={DashboardSistemaConstrutivo} />
                <PrivateRoute path="/atividade" component={DashboardAtividade} />
                <PrivateRoute path="/ocorrencias" component={DashboardOcorrencias} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/trocarsenha" component={TrocarSenha} />
                <PrivateRoute path="/atividadenovo" component={DashboardAtividadeNovo} />
                <PrivateRoute path="/ocorrenciasgravidadelist/:id/:gravidade" component={OcorrenciasGravidadeList} />
                <PrivateRoute path="/ocorrenciastipostatuslist/:id/:tipostatus" component={OcorrenciasStatusList} />
                <PrivateRoute path="/ocorrenciasareaslist/:id/:area" component={OcorrenciasAreasList} />
                <PrivateRoute path="/atividadesprioridadelist/:id/:prioridade/:dataini/:datafim" component={AtividadesPrioridadeList} />
                <PrivateRoute path="/atividadestipoatividadelist/:id/:tipoatividade/:dataini/:datafim" component={AtividadesTipoAtividadeList} />
                <PrivateRoute path="/atividadessistemalist/:id/:sistema/:dataini/:datafim" component={SistemaConstrutivoList} />
                <PrivateRoute path="/atividadestipoexecutorlist/:id/:tipo/:dataini/:datafim" component={TipoExecutorList} />
                <PrivateRoute path="/ocorrenciasareasdetalheslist/:id/:area" component={OcorrenciasAreasDetalhesList} />
                <PrivateRoute path="/ocorrencia/:id" component={Ocorrencia} />
            </Switch>
        </>
    );
}

export default withRouter(AppRouter);
