import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context';
import auth from '../../service/auth';
import { Toast } from 'primereact/toast';

const Login = () => {

    const history = useHistory();
    const context = useContext(UserContext);
    const [myUser, setUser] = useState();
    const toast = useRef(null);

    const handleInputChange = ev => {
        let name = [ev.target.name];
        let value = ev.target.value;
        setUser(
            { ...myUser, [name]: value }
        )
    }

    const handleSubmit = ev => {
        ev.preventDefault();
        auth.post('/usuarios/auth', myUser)
            .then(res => {
                sessionStorage.setItem('simp-cli-token', res.data.token);
                sessionStorage.setItem('idcliente', res.data.idcliente);
                sessionStorage.setItem('idusuario', res.data.idusuario);
                sessionStorage.setItem('usuario', res.data.usuario);

                if (!res.data.trocarsenha) {
                    context.alterLogin(true);
                    history.push('/');
                    window.location.reload();
                }
                else {
                    sessionStorage.setItem('trocarsenha', res.data.trocarsenha);
                    history.push('/trocarsenha');
                    window.location.reload();
                }
            })
            .catch(error => {
                alert('Usuário ou senha inválidos');
            })
    }

    return (
        <div>
            <Toast ref={toast} />
            <div className="p-p-4 p-d-flex p-jc-center">
                <div className="card p-mr-2">
                    <div className="p-d-flex p-jc-center">
                        <img className="photo" src={`assets/simp/logo-principal.png`}
                            style={{
                                width: '150px'
                            }}></img>
                    </div>
                    <div className='p-mt-4'>
                        <h1 style={{ textAlign: "center" }}>Bem vindo</h1>
                        <h4 style={{ textAlign: "center" }}>Informe os dados para efetuar o login. </h4>

                        <br></br>
                        <form onSubmit={handleSubmit}>
                            <div className="p-mr-2">
                                {/* <span className="p-float-label"> */}
                                {/* <label htmlFor="usuario">E-mail</label> */}
                                <br></br>
                                <InputText id="usuario" type="text" name="usuario" placeholder='E-mail' onChange={handleInputChange}
                                    className="p-inputtext-lg p-d-block" style={{ width: "370px" }} />
                                {/* <br></br> */}
                                {/* </span> */}
                            </div>

                            <div className="p-mr-2">
                                {/* <span className="p-float-label"> */}
                                {/* <label htmlFor="senha">Senha</label> */}
                                <br></br>
                                <InputText id="senha" type="password" name="senha" placeholder='Senha' onChange={handleInputChange}
                                    className="p-inputtext-lg p-d-block" style={{ width: "370px" }} />

                                {/* </span> */}

                            </div>
                            <div className="p-mr-2">
                                <br />
                                <br></br>
                                <Button type="submit" label="Entrar" className="p-button-lg p-d-block p-mx-auto" style={{ width: "370px" }} />
                                <br></br>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login