import { Skeleton } from 'primereact/skeleton';
import React, { useState, useEffect } from 'react';
import api from '../../service/api';

export const CardsAtividade = (props) => {

    const emptyInfo = {
        Finalizadas: null,
        Ressalva: null,
        Adiadas: null,
        NaoExecutada: null,
        Total: null
    };

    const [info, setInfo] = useState(emptyInfo);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/atividade/info/dataini/${props.dataIni}/datafim/${props.dataFim}/id/${sessionStorage.getItem("idcliente")}`)
            .then(res => {
                setInfo(res.data);
                setLoading(false);
            });
    }, [props.dataIni, props.dataFim]);


    return (
        <>
            {!loading && (
                <>
                    {/* revenue = azul */}
                    {/* visitors = verde */}
                    {/* purchases = amarelo */}
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Sucesso</span>
                            <span className="detail">Finalizadas com sucesso</span>
                            <span className="count visitors">{info.Finalizadas}</span>
                            {/* revenue = azul */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Ressalva</span>
                            <span className="detail">Finalizadas com ressalva</span>
                            <span className="count purchases">{info.Ressalva}</span>
                            {/* visitors = verde */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Reagendada</span>
                            <span className="detail">Atividades reagendadas</span>
                            <span className="count purchases">{info.Adiadas}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Justificada</span>
                            <span className="detail">Não executadas com justificativa</span>
                            <span className="count vermelho">{info.NaoExecutada}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="card summary">
                            <span className="title">Pendentes</span>
                            <span className="detail">Atividades pendentes</span>
                            <span className="count purchases">{info.Total - info.Finalizadas - info.Ressalva -
                                info.Adiadas - info.NaoExecutada}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="card summary">
                            <span className="title">Planejadas</span>
                            <span className="detail">Atividades planejadas</span>
                            <span className="count revenue">{info.Total}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                </>
            )}
            {loading && (
                <>
                    <div className="p-col-12 p-lg-3">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <Skeleton height="5rem" />
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <Skeleton height="5rem" />
                    </div>

                </>
            )}
        </>
    );
}