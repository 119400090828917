import React from 'react';
import { GraficoOcorrenciasAreasDetalhes } from './ocorrenciasAreasDetalhes';

export const OcorrenciasAreasDetalhesList = (props) => {

    // const [idArea, setIdArea] = useState(0);
    // const [teste, setTeste] = useState(null);
    // const [opa, setOpa] = useState(null);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     setLoading(true);
    //     api.get(`/ocorrencias/grupoareadetalhes/id/${props.idCliente}/idarea/${props.idArea}`)
    //         .then(res => {
    //             setGrafico(res.data);
    //             setLoading(false);
    //         })
    // }, [props.idCliente, props.idArea]);

    // componentDidMount() {
    //      this.setState({ loading: true });
    //     const { id, area } = this.props.match.params;
    //     this.setState({ opa: id, parametroUrl: area, teste: 33, loading: false });
    //     console.log('id: ', id, 'idarea: ', this.state.idArea);
    // };

    const getGrafico = () => {
        return (
            <React.Fragment>
                <GraficoOcorrenciasAreasDetalhes idCliente={sessionStorage.getItem("idcliente")}
                    idArea={props.match.params.id} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="content-section introduction">
                <h2> Ocorrências por Grupo de Área </h2>
            </div>

            <div className="content-section implementation">
                <div className="card">
                    <h4> Grupo de Área: {props.match.params.area} </h4>
                    <div className="card">
                        {getGrafico()};
                    </div>
                </div>
            </div>
        </div>
    )
}

